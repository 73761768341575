import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './revealImage.css';

import gsap, { Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Reveal = ({ children }) => {
  const revealRef = useRef(null);

  useEffect(() => {
    let Image = revealRef.current.children[0].children[0];
    let Wrapper = revealRef.current.children[0];
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: revealRef.current,
        start: 'top bottom',
      },
    });
    tl.set(Wrapper, { autoAlpha: 1, delay: 0.3 });
    tl.from(Wrapper, 1.3, {
      yPercent: 100,
      ease: Power2.out,
    });
    tl.from(Image, 1.3, {
      yPercent: -100,
      scale: 1.2,
      delay: -1.3,
      ease: Power2.out,
    });
  }, []);

  return (
    <Container className="reveal-img" ref={revealRef}>
      <div className="reveal-wrap">{children} </div>
    </Container>
  );
};

Reveal.propTypes = {
  children: PropTypes.any,
};

export default Reveal;
