import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  .reveal-wrap {
    overflow: hidden;
    visibility: hidden;
    position: relative;
    height: 100%;
  }
  .gatsby-image-wrapper {
    transform-origin: bottom;
  }

  ${MEDIA.TABLET`
    flex-grow: 1;
  `}
`;
