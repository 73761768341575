import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Reveal from '../animations/revealImage';

/*const Reveal = ({ children }) => {
  const revealRef = useRef(null);

  useEffect(() => {
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: revealRef.current,
        start: 'top bottom',
      },
    });
    const Image = revealRef.current.children[0];
    tl.from(revealRef.current, 2, { y: 100, ease: Power4.out });
    tl.from(Image, 2, {
      y: -100,
      delay: -1,
      ease: Power4.out,
    });
  }, []);

  return <div ref={revealRef}>{children}</div>;
};
*/

const Image = ({ src, alt }) => {
  return (
    <Reveal>
      <Img fluid={src ? src.childImageSharp.fluid : {}} alt={alt} />
    </Reveal>
  );
};

Image.propTypes = {
  src: PropTypes.any.isRequired,
  alt: PropTypes.string,
};

export default Image;

/*

const Image = ({ src, alt }) => {
  return <Img fluid={src ? src.childImageSharp.fluid : {}} alt={alt} />;
};

Image.propTypes = {
  src: PropTypes.any.isRequired,
  alt: PropTypes.string,
};

export default Image; */
